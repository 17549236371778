import React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import BlogHeader from "../../components/blog-header/blog-header.component"
import ShareSocialMediaIcons from "../../components/share-social-media-icons/share-social-media-icons.component"
import * as S from "./update.template.styles"
import ArticlesSliderBanner from "../../components/articles-slider-banner/articles-slider-banner.component"

export const query = graphql`
  query UpdateQuery($id: String!) {
    wpUpdate(id: { eq: $id }) {
      title
      date(formatString: "MMMM DD, YYYY")
      content
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      id
      uri
      slug
    }
  }
`

const Update = ({ data }) => {
  const { seo, title, content, featuredImage, date, uri, slug } = data.wpUpdate
  const canonical = `${process.env.GATSBY_WEBSITE_URL}/updates/${slug}/`
  return (
    <Layout seo={{ ...seo, canonical }}>
      <BlogHeader title={title} featuredImage={featuredImage} date={date} />
      <S.Wrapper>
        <S.CustomContainer>
          <ShareSocialMediaIcons slug={uri} />
          <S.Content dangerouslySetInnerHTML={{ __html: content }} />
        </S.CustomContainer>
      </S.Wrapper>
      {/*<ArticlesSliderBanner presentSlug={uri} />*/}
      {/*<Img fluid={featuredImage.imageFile.childImageSharp.fluid} />*/}
    </Layout>
  )
}

export default Update
